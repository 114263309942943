import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import Layout from "src/components/layout"
import SubpageBanner from "src/components/SubpageBanner"
import SEO from "src/components/seo"
import SimpleText from "src/components/simple-text"
import Breadcrumbs from "src/components/Breadcrumbs"
import Sidebar from "src/components/Sidebar"

const breadcrumbs = [
  {url: "womens-health-impact", handle: "Women's Health Impact"},
  {
    url: "womens-health-impact/pilot-study",
    handle: "Pilot Study",
  },
]

const PilotStudy = () => (
  <StaticQuery
    query={graphql`
      query {
        one: file(relativePath: { eq: "excellerator-3.jpg" }) {
          childImageSharp {
            fixed(quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        background: file(relativePath: { eq: "discarded-cells-banner.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      const one = data.one.childImageSharp.fixed
      const background = data.background.childImageSharp.fluid

      return (
        <Layout>
          <SEO title="Pilot Study - ExCellerator" />
          <SubpageBanner image={background} heading="Pilot Study" />
          <div className="wrapper wrapper--narrow p-bottom p-sides">
            <Breadcrumbs items={breadcrumbs} />
            <div className="split-contain wrapper">
              <div className="split-contain__sidebar">
                <Sidebar image={one} />
              </div>
              <div className="split-contain__contain">
                <SimpleText
                  copy="Immediately following the introduction of the ThinPrep Pap Test system, clients began to experience and complain about results of specimen inadequacy from samples they contended were quality collections. We experienced clients actually switching back to the conventional method out of frustration. We knew that the new system was better overall, so we set out to find out what was causing the issue."
                >
                  <p>A Pilot Study was conducted to prove 3 things:</p>
                  <ul>
                    <li>Sample remains on the cytobrush that is to be discarded</li>
                    <li>The exCellerator can safely and effectively aid in capture of the sample</li>
                    <li>The sample can be processed and used to benefit diagnostically</li>
                  </ul>
                  <h3>Sample Collection:</h3>
                  <ul>
                    <li>Vial #1, spatula only</li>
                    <li>Vial #2, endocervical brush only – standard rinse procedure</li>
                    <li>Vial #3, exCellerator™-aided removal of residual cells from bristles</li>
                  </ul>
                  <h3>Testing:</h3>
                  <ul>
                    <li>All vials were processed and screened</li>
                  </ul>
                  <h3>Results/Conclusions:</h3>
                  <ul>
                    <li>Abundant sample was visible in all 10 - #3 Vials (Residual Sample)</li>
                    <li>Quality, satisfactory Preparations were made from all #3 vials</li>
                    <li>All Cytologic Dx were comparable to primary vials</li>
                    <li>Therefore: Substantial residual cells remain in the bristles of the brush</li>
                  </ul>
                </SimpleText>
              </div>
            </div>
          </div>
        </Layout>
      )
    }}
  />
)

export default PilotStudy
